.author-image {
    .img {
        width: 100px;
        height: 100px;
        display: block;
        border-radius: 50%;
        margin: auto;
        background-size: cover;
        background-position: center;
    }
}

.single-post {
    .post-content img, .post-title, .post-meta {
        max-width: 700px;
        width: 100%;
        margin: auto;
    }

    .post-meta {
        margin: 20px auto 0;
    }

    .post-content {
        iframe {
            min-height: 350px;
        }

        &:after {
            content: '';
            display: block;
            clear: both;
        }

        img[src*='#right'], img[src*='#left'],
        figure.left, figure.right {
            max-width: 250px;
        }
    }

    .post-title {
        font-size: 5rem;
    }

    .large {
        img {
            max-width: none;
        }
    }

    figure {
        position: relative;
        margin-bottom: 25px;

        
        p {
            margin: 0; /* jekyll generates paragraphs in figure elements */
        }


        &.left, &.right, &.large {
            margin-bottom:0;
            
            figcaption {
                position: static;
                width: auto;
                text-align: center;
                padding-bottom: 10px;

                &:before {
                    display: none;
                }
            }

            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: none;
            }
        }

        &.large {
            max-width: none;
            width: auto;

            img {
                width: 100%;
            }
        }

        &.left {
            float: left;
            margin-left: 0;
            margin-right: 20px;
            margin-top: 20px;
        }

        &.right {
            float: right;
            margin-left: 0;
            margin-right: 20px;
            margin-top: 20px;
        }

        img {
            display: block;
        }
    }

    figcaption {
        position: absolute;
        top: 0;
        right: 670px;
        font-style: italic;
        text-align: right;
        width: 180px;
        padding-top: 10px;
        line-height: 1.5;
        color: #999;

        &:before {
            position: absolute;
            top: 0;
            right: 0;
            content: '';
            width: 30px;
            height: 3px;
            background: gray;
        }
    }

    img[src*='#right'], img[src*='#left'] {
        width: auto;
        height: auto;
    }

    img[src*='#right'] {
        float: right;
        margin: 0 0 10px 10px;
    }

    img[src*='#left'] {
        float: left;
        margin: 0 10px 10px 0;
    }

    .album {
        height: 400px;
        white-space: nowrap;
        background: rgba(250, 250, 250, 0.8);
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        padding: 20px;
        overflow-x: auto;
        overflow-y: hidden;
        user-select: none;
        margin: 30px 0;

        img {
            display: inline-block;
            height: 100%;
            width: auto;
            border: 3px solid rgba($color-white, 0.5);
            box-shadow: 0 3px 5px rgba($color-black, 0.3);
            margin: 0 10px;
            box-sizing: border-box;
            cursor: pointer;
        }

        figure {
            margin: 0 10px;
            width: auto;
            max-width: none;
            height: 100%;
            display: inline-block;

            img {
                margin: 0;
            }
        }

        figcaption {
            position: absolute;
            width: auto;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 20px 5px 5px;
            top: auto;
            color: $color-white;
            text-align: center;
            background: linear-gradient(to top, rgba($color-black,0.8) 0%, rgba($color-black,0) 100%);

            &:before {
                display: none;
            }
        }
    }
}

.post-content {
    img {
        cursor: pointer;
    }

    a {
        text-decoration: underline;
    }

    ol,
    ul {
        padding-left: 3rem;
    }

    ol ol,
    ul ul,
    ul ol,
    ol ul {
        margin: 0 0 0.4em 0;
        padding-left: 2em;
    }

    ol p,
    ul p {
        width: 100%;
        margin-bottom: auto;
    }

    .tip {
        padding: 12px 24px 12px 30px;
        margin: 2em 0;
        background-color: #f8f8f8;
        position: relative;
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
        box-sizing: border-box;
        border-left: 4px solid $orangered;

        &:before {
            background-color: $orangered;
            position: absolute;
            top: 14px;
            left: -12px;
            color: #fff;
            content: "!";
            width: 20px;
            height: 20px;
            border-radius: 100%;
            text-align: center;
            line-height: 20px;
            font-weight: bold;
            font-family: 'Dosis', 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;
            font-size: 14px;
        }
    }
}

.ds-thread, #disqus_thread {
    width: 90%;
    max-width: 700px;
    margin: auto;
}

.fluid-width-video-wrapper {
    max-width: 650px;
    margin: auto;
    padding: 0;
}

.view-comments {
    display: inline-block;
    padding: 7px 22px;
    font: 600 14px 'Open Sans', Serif;
    border-radius: 4px;
    border: 2px solid $color-text-link;
		background: #fff;
		color: $color-text-link;

    &:hover {
        text-decoration: none;
        color: $color-text-link-hover;
        border-color: $color-text-link-hover;
    }
  }

.bg-scroll {
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    height: 500px;
    margin: 0 0 1.75em 0;
}
