$color-selection: #D6EDFF;


$color-text-normal: #3A4145;

$color-text-header: #2E2E2E;

$color-text-link: #4A4A4A;
$color-text-link-hover: #111;

$color-hr: #EFEFEF;

$color-quote-border: #4A4A4A;

$color-cv-text: #4A4A4A;
$color-cv-blue: #4FC1E9;

$color-mark: #fdffb6;

$color-kbd-color: #666;
$color-kbd-bg: #F4F4F4;
$color-kbd-bd: #CCC;

$color-table-bd: #EFEFEF;
$color-table-head-bg: #F6F6F6;

$color-white: #ffffff;
$color-black: #000000;

$light-green: #85bd6a;
$orangered: #FF4500;

