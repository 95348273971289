/* some data is duplicated from other files to allow an easy merge of updates 
   from the forked theme without destroying the css for this page */

.category {
    width: 90%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    word-wrap: break-word;
    margin-bottom: 50px;
    padding: 0;

    li {
        margin-bottom: 8px;
        list-style-type: none;
    }

    .article {
        text-align: left;

        .post-header {
            overflow: hidden;
            border-bottom: 1px solid #eeeeee;
            margin-bottom: 10px;
        }

        .title a {
            text-decoration: none;
        }

        .date {
            float: right;
            font-size: 12px;
            text-transform: uppercase;
            color: rgba(0,0,0,0.2);
            line-height: 24px;
            margin-top: 7px;
            margin-bottom: 0;
        }
    }
}
