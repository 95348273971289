.md-card {
    color: $color-cv-text;
    font-size: 1.5rem;
    padding: 25px 0 5px 0;
    margin: 0 auto 50px auto;


    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    dl {
        margin: 0;
    }

    dt.time {
        color: $color-cv-blue;
    }

    dd {
        padding-top: 7px;
        margin-left: 200px;

        h3 {
            margin-bottom: 0;
        }

        p {
            margin: 0;
        }
    }

    .title {
        display: flex;
        align-items: baseline;
        font-size: 2rem;

        h2 {
            padding-left: 10px;
            color: $color-cv-text;
        }
    }

    &.no-border {
        padding: 0;
        margin: 0 auto;

        padding {
            margin: 0;
        }
    }

    & > * {
        padding: 0 25px 0 25px;
    }
}


.shadow {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
}

.timeline-container {
    width: 80%;
    padding: 50px 0 0 0;
    margin: 50px auto;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 10px;
        margin-left: -1px;
        width: 2px;
        height: 100%;
        background: #CCD1D9;
        z-index: 1
    }
}

.timeline-block {
    display: flex;
    justify-content: space-between;


    .marker {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid #F5F7FA;
        background: $color-cv-blue;
        margin-top: 10px;
        z-index: 9999
    }
}

.timeline-block > .time {
    position: absolute;
    margin-left: -180px;
    margin-top: 3px;
    color: $color-cv-blue;
    text-align: right;
    width: 170px;
}

.timeline-content {
    width: 95%;
    padding: 0 15px;
    color: #666;


    h3 {
        margin-top: 5px;
        margin-bottom: 0;
    }

    span {
        font-size: 15px;
        font-weight: 600;
    }

    .time {
        margin-top: 5px;
        display: none;
        color: $color-cv-blue;
    }
}

.timeline-content, .md-card.project {
    li {
        font-size: 14px;
        line-height: 1.5em;
        word-spacing: 1px;
        color: #888;
    }
}

.md-card.project {
    .meta {
        display: flex;
        justify-content: space-between;

        .team {
            color: #bbb;
        }

        .time {
            color: $color-cv-blue;
            font-size: 1.5rem;
            margin-left: 0.8rem;
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        ul {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
        }

        li {
            padding-right: 10px;
            list-style-type: none;
            font-weight: 600;
        }

        span {
            font-size: 2rem;
        }
    }
}


@media screen and (max-width: 768px) {
    .timeline-container:before {
        left: 8px;
        width: 2px;
    }

    .timeline-block {
        width: 100%;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 1081px) {

    .timeline-block > .time {
        display: none;
    }

    .timeline-content > .time {
        display: block;
    }
}

@media only print {
    .author-cover, .author-image, .author-meta, .logo-readium, .author-bio,
    .timeline-block > .time, .marker, .timeline-container:before, .go-to-top,
    .icon-briefcase, .site-footer {
        display: none !important;
    }

    .timeline-content > .time {
        display: block;
    }

    .timeline-container {
        padding: 0;
        margin: 0 auto;
    }

    .shadow {
        box-shadow:none;
    }

    .md-card {
        margin: 0 auto;
    }

    .employment-heading, .project-heading {
        page-break-before: always;
    }

    .md-card, .timeline-block, .education {
        page-break-inside: avoid;
    }
}
