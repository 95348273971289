.main-header {
    margin-bottom: 50px;
    padding-bottom: 5px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;

    &.has-cover {
        .page-title,
        .page-title a,
        .page-description,
        .nav a {
            color: $color-white;
            text-shadow: 1px 1px 3px rgba($color-black, 0.5);
        }

        .nav a {
            color: $color-white;
            text-shadow: 1px 1px 3px rgba($color-black, 0.5);
        }

        a.page-link {
            color: $color-white;
            text-shadow: 1px 1px 3px rgba($color-black, 0.5);
            text-shadow: -2px 2px 3px rgba($color-black, 0.5);
        }

        .nav {
            &:before, &:after {
                background: linear-gradient(to right, rgba($color-white, 0) 0%,rgba($color-white, 1) 50%,rgba($color-white, 0) 100%);
            }
        }
    }

    .page-title {
        width: 90%;
        max-width: 500px;
        margin: auto;
    }

    .page-description {
        width: 90%;
        max-width: 500px;
        margin: auto auto 5px auto;
    }

    @media (min-width: 500px){
        /*mobile*/
        .nav {
            margin: auto 20px;
            flex-wrap: wrap;
            justify-content: center;

            ul {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
            }

            li {
                margin: 5px 10px;
                min-width: inherit;
            }

            .navbar-left {
                display: flex;
                float: left;
                align-self: left;
                text-align: left;
                align-content: left;
                justify-content: left;
                padding-left: 5%;
            }
    
            .navbar-right{
                float: right;
                align-self: right;
                text-align: right;
                align-content: right;
                justify-content: right;
                padding-right: 5%;
            }   
            
            a.page-link {
                margin: 5px 10px;
                display: inline-block;
                font-size: 18px;
                font-weight: 500;
                text-transform: uppercase;
            }
        }
    }

    @media (max-width: 500px){
        /*desktop*/
        .nav {
            margin: auto 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            ul {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
            }

            li {
                margin: 5px 10px;
                min-width: inherit;
            }

            .navbar-left {
                display: flex;
                float: center;
                align-self: center;
                text-align: center;
                align-content: center;
                justify-content: center;
                padding-left: 1%;
            }
    
            .navbar-right{
                float: center;
                align-self: center;
                text-align: center;
                align-content: center;
                justify-content: center;
                padding-right: 1%;
            }   
            
            a.page-link {
                margin: 5px 10px;
                display: inline-block;
                font-size: 16px;
                text-transform: uppercase;
            }
        }
    }

    .page-title {
        a {
            font-family: Merriweather;
            font-size: 70px;
            word-spacing: 15px;
            line-height: 1.0;
            text-decoration: none;
            text-shadow: 1px 1px 5px rgba($color-black, 0.3);
        }
    }

    .page-description {
        font-size: 18px;
        font-weight: normal;
        font-style: italic;
        font-family: 'Merriweather', 'Hiragino Sans GB', 'Microsoft YaHei', 'WenQuanYi Micro Hei', sans-serif;
        color: rgba($color-text-header, 0.8);
    }
}

.site-footer:before,
.author-info:after {
    content: '';    
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, rgba($color-black,0) 0%,rgba($color-black,1) 50%,rgba(0,0,0,0) 100%);
}

.author-info:after {
    display: none;
}

.author-info:before {
    display: block;
}

// post list
.post-title {
    font-size: 3.6rem;
}

.post-excerpt, .post-content {
    text-align: left;
}

.post-meta {
    margin-bottom: 20px;
    font-style: italic;
    a {
        text-decoration: underline;
        font-size: 15px;
        color: #666666;
    }
}

.post-excerpt {
    p {
        text-indent: 2em;
        margin-bottom: 10px;
    }

    .read-more {
        font-style: italic;
        text-decoration: underline;
    }
}

.post {
    font-size: 14px;
    line-height: 2;
}

.content {
    .short-diver {
        position: relative;
        margin: 20px auto 40px;

        &:after {
            content: '';
            display: block;
            width: 40px;
            height: 4px;
            background: #eeeeee;
            margin: 60px auto 0;
        }
    }
}

// pagination
.pagination {
    font-size: 15px;
    margin-top: 20px;
    position: relative;
}

.newer-posts, .older-posts {
    position: absolute;
    top: 0;
    left: 0;
}

.older-posts {
    left: auto;
    right: 0;
}


.site-footer {
    max-width: 500px;
    width: 90%;
    padding: 20px 0;
    font-size: 12px;
    line-height: 1.3;
}

.site-footer p {
    margin: 0;
}

.site-footer:before {
    margin-bottom: 20px;
}

.go-to-top {
    background-image: url('../assets/arrow-up.png');
    border-radius: 25px;
    top: 30px;
    bottom: auto;
    width: 40px;
    height: 40px;
    position: fixed;
    left: 50%;
    transform: translateX(400px);
    border: none;
    display: none;
    opacity: 0.8;
    transition: all 0.3s;

    &.less-opacity {
        opacity: 0.2;
    }

    &:hover {
        opacity: 1;
    }

    &:focus {
        outline: none;
        opacity: 1;
    }
}


// make the content center and given a width
.post-list .post,
.pagination,
.site-footer,
.single-post .post-content > h1,
.single-post .post-content > h2,
.single-post .post-content > h3,
.single-post .post-content > h4,
.single-post .post-content > h5,
.single-post .post-content > h6,
.single-post .post-content > p,
.single-post .post-content > ul,
.single-post .post-content > ol,
.single-post .post-content > blockquote,
.single-post .post-content .single-post-header,
.single-post .post-content > table,
.single-post .post-content > address,
.single-post .post-content > article,
.single-post .post-content > aside,
.single-post .post-content > canvas,
.single-post .post-content > dd,
.single-post .post-content > dl,
.single-post .post-content > fieldset,
.single-post .post-content > figcaption,
.single-post .post-content > figure,
.single-post .post-content > footer,
.single-post .post-content > form,
.single-post .post-content > header,
.single-post .post-content > hgroup,
.single-post .post-content > li,
.single-post .post-content > main,
.single-post .post-content > nav,
.single-post .post-content > noscript,
.single-post .post-content > output,
.single-post .post-content > section,
.single-post .post-content > tfoot,
.single-post .post-content > video,
.single-post .post-content > iframe,
.single-post .post-content > .tip,
.timeline-container,
.md-card,
ol,
.read-next {
    width: 90%;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    word-wrap: break-word;
}