@import './_color';
@import './_size';

html {
    height: 100%;
    max-height: 100%;
    font-size: 62.5%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    height: 100%;
    max-height: 100%;
    font-family: 'Merriweather', 'Hiragino Sans GB', 'Microsoft YaHei', 'WenQuanYi Micro Hei', sans-serif;
    letter-spacing: 0.01rem;
    font-size: $size-font-normal;
    line-height: 1.75em;
    color: $color-text-normal;
    font-feature-settings: 'kern' 1;
    text-rendering: geometricPrecision;
    text-align: center;
}

::-moz-selection {
    background: $color-selection;
}

::selection {
    background: $color-selection;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
    color: $color-text-header;
    line-height: 1.15em;
    margin: 0 0 0.7em 0;
    font-family: 'Open Sans', 'Hiragino Sans GB', 'Microsoft YaHei', 'WenQuanYi Micro Hei', sans-serif;
    text-rendering: geometricPrecision;
}

h1 {
    font-size: $size-font-h1;
    letter-spacing: -2px;
    text-indent: -3px;
}

h2 {
    font-size: $size-font-h2;
    letter-spacing: -1px;
}

h3 {
    font-size: $size-font-h3;
}

h4 {
    font-size: $size-font-h4;
}

h5 {
    font-size: $size-font-h5;
}

h6 {
    font-size: $size-font-h6;
}

a {
    text-decoration: none;
    color: $color-text-link;
    transition: color 0.3s ease;

    &:hover {
        text-decoration: underline;
        color: $color-text-link-hover;
    }
}

p,
ul,
ol,
dl {
    font-feature-settings: 'liga' 1, 'onum' 1, 'kern' 1;
    margin: 0 0 1.75em 0;
    text-rendering: geometricPrecision;
}

dl dt {
    float: left;
    width: 180px;
    overflow: hidden;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 700;
    margin-bottom: 1em;
}

dl dd {
    margin-left: 200px;
    margin-bottom: 1em
}

li {
    margin: 0.4em 0;
}

li li {
    margin: 0;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: $color-hr 1px solid;
    margin: 3.2em auto;
    padding: 0;
}

blockquote {
    box-sizing: border-box;
    margin: 1.75em 0 1.75em -2.2em;
    padding: 0 0 0 1.75em;
    border-left: $light-green 0.4em solid;
}

blockquote blockquote {
    margin-left: 0;
}

blockquote p {
    margin: 0.8em 0;
    font-style: italic;
}

blockquote small {
    display: inline-block;
    margin: 0.8em 0 0.8em 1.5em;
    font-size: 0.9em;
    color: #CCC;
}

blockquote small:before {
    content: '\2014 \00A0';
}

blockquote cite {
    font-weight: 700;
    font-style: normal;
}

blockquote cite a {
    font-weight: normal;
}

mark {
    background-color: $color-mark;
}

kbd {
    display: inline-block;
    margin-bottom: 0.4em;
    padding: 1px 8px;
    border: $color-kbd-bd 1px solid;
    color: $color-kbd-color;
    text-shadow: $color-white 0 1px 0;
    font-size: 0.9em;
    font-weight: 700;
    background: $color-kbd-bg;
    border-radius: 4px;
    box-shadow: 0 1px 0 rgba($color-black, 0.2), 0 1px 0 0 $color-white inset;
}

table {
    box-sizing: border-box;
    margin: 1.75em 0;
    background-color: transparent;
}

table th,
table td {
    padding: 8px;
    line-height: 20px;
    text-align: left;
    vertical-align: top;
    border-top: $color-table-bd 1px solid;
}

table th {
    color: $color-black;
}

table caption + thead tr:first-child th,
table caption + thead tr:first-child td,
table colgroup + thead tr:first-child th,
table colgroup + thead tr:first-child td,
table thead:first-child tr:first-child th,
table thead:first-child tr:first-child td {
    border-top: 0;
}

table tbody + tbody {
    border-top: $color-table-bd 2px solid;
}

table table table {
    background-color: $color-white;
}

table tbody > tr:nth-child(odd) > td,
table tbody > tr:nth-child(odd) > th {
    background-color: $color-table-head-bg;
}

table.plain tbody > tr:nth-child(odd) > td,
table.plain tbody > tr:nth-child(odd) > th {
    background: transparent;
}

iframe,
.fluid-width-video-wrapper {
    display: block;
    margin: 1.75em 0;
}


/* When a video is inside the fitvids wrapper, drop the
margin on the iframe, cause it breaks stuff. */

.fluid-width-video-wrapper iframe {
    margin: 0;
}

