/* Override prism.js css */
pre, pre[class*="language-"] {
    margin-bottom: 1.8em !important; 
}

pre code { 
    display:block; 
    width:90%; 
    max-width:650px; 
    margin:auto;
    white-space:pre-wrap!important;
}

/* Custom CSS for GitHub link in about page */
.github-button-container {
    display: flex;
    justify-content: center;

    iframe {
        min-height: inherit !important;
    }
}
